import React, { useState,useEffect,useRef } from 'react';
import { BrowserRouter, BrowserRouter as Router,Route,Switch } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css'
// import $ from "jquery";
import '../node_modules/jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AppRouter } from './route/route';
import { Provider } from 'react-redux';
import { createStore } from './redux/configureStore';


// require('dotenv').config()

const store = createStore();

function App() {

  return (
  <>
      <Provider store={store}>
      <Router>
        <AppRouter />
      </Router>
      </Provider>
      
      
  </>
  );
}
export default App;
