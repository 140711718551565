import { useState, useEffect } from "react";
import axios from "axios"
import Web3 from "web3"
// import { Pagination, Stack } from '@mui/material';

import Table from 'react-bootstrap/Table';
import { serverPath } from "../../baseUrl";
import { Pagination, Stack } from "@mui/material";
import baseIco from "../../images/baseIco.png"
import erc20 from "../../images/erc20.svg"
import { StatsComponent } from "./statsComponent";

export default function ClaimTX() {
    const [data, setData] = useState([]);
    const [page, setPage]=useState(1)
    const [limit, setLimit]=useState(10)
    const [noOfRequest, setnoOfRequest]=useState(0)
  
    async function getData() {
        console.log(sessionStorage.getItem("token"));

        let payload = {
            page:page
          }
        
        await axios.post(`${serverPath}/admin/txs`,payload, {
            headers: {
                'Content-Type':'application/json',
                "musto-access-token": sessionStorage.getItem("token").toString()
            }
        })
            .then(res => {
                console.log(res.data);
                setData(res.data.txs)
                setnoOfRequest(res.data?.count)
                setLimit(res.data?.limit)
            })
            .catch(err => {
                console.log(err);
            })
    }
    // async function getData() {
    //     console.log(sessionStorage.getItem("token"));
        
    //     await axios.post(`${serverPath}/admin/txs`,{}, {
    //         headers: {
    //             'Content-Type':'application/json',
    //             "musto-access-token": sessionStorage.getItem("token").toString()
    //         }
    //     })
    //         .then(res => {
    //             console.log(res.data);
    //             setData(res.data.txs)
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    async function updateApproval(approval, tx) {
        await axios.post(`${serverPath}/admin/approve-tx`, {
            tx, approval,
        }, {
            headers: {
                "musto-access-token": sessionStorage.getItem("token")
            }
        })
            .then(res => {
                console.log(res.data.error)
                window.location.reload()
            })
            .catch(err => {
                console.log(err);
            })
    }

    function logout() {
        sessionStorage.removeItem("token")
        window.location.href = "/admin/login"
    }

    useEffect(() => {
        const session = sessionStorage.getItem("token")
        if (session === null) {
            window.location.href = "/admin/login"

        } else {
            getData()

        }

    }, [])

    const handleChange = (event, value) => {
        setPage(value);
      };

    useEffect(() => {
        getData()
    }, [page])

    let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
        timeZone: "America/Los_Angeles",
      };

      var dateFormtter = new Intl.DateTimeFormat("en-US", options)


    return (
    <>
    <div className="adminMain">
        <div className="adminMainInner">
            <StatsComponent />
            <div className="claimTxMain">
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                        <h2 className="head">Claim Request</h2>
                        <button className="btn sBtn" onClick={() => logout()}> Logout </button>
                    </div>
                    <Table responsive >
                        <thead>
                            <tr>
                                <th>TX CustomHash</th>
                                <th>From</th>
                                <th>Receiver</th>
                                <th>State</th>
                                <th>Status</th>
                                <th>Amount</th>
                                <th>Chain Id</th>
                                <th>Transaction Hash</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((item, i) => {
                                    return <tr key={i}>
                                        <td>{(item?.txCustomHash).substring(0, 15) + "..."}</td>
                                        <td>
                                            {process.env.REACT_APP_ETH_CHAIN_ID == item?.chainId ?
                                            <a href={`${process.env.REACT_APP_ethExplorer}/address/${item?.from}`} target="_blank" rel="noopener noreferrer">
                                                {item?.from.substring(0, 4) + "..."+item?.from.substring(item?.from.length-4, item?.from.length)}
                                            </a>
                                            :
                                            <a href={`${process.env.REACT_APP_baseExplorer}/address/${item?.from}`} target="_blank" rel="noopener noreferrer">
                                                {item?.from.substring(0, 4) + "..."+item?.from.substring(item?.from.length-4, item?.from.length)}
                                            </a>
                                            }
                                        </td>
                                        <td>
                                            {process.env.REACT_APP_ETH_CHAIN_ID == item?.chainId ?
                                            <a href={`${process.env.REACT_APP_ethExplorer}/address/${item?.receiver}`} target="_blank" rel="noopener noreferrer">
                                                {item?.receiver.substring(0, 4) + "..."+item?.receiver.substring(item?.receiver.length-4, item?.receiver.length)}
                                            </a>
                                            :
                                            <a href={`${process.env.REACT_APP_baseExplorer}/address/${item?.receiver}`} target="_blank" rel="noopener noreferrer">
                                                {item?.receiver.substring(0, 4) + "..."+item?.receiver.substring(item?.receiver.length-4, item?.receiver.length)}
                                            </a>
                                            }
                                        </td>
                                        <td>{item?.approval}</td>
                                        <td>{item?.status == 1 ? "claimed" : "unclaimed"}</td>
                                        <td>{item?.amount}</td>
                                        <td>
                                            <div>
                                                {item?.chainId == process.env.REACT_APP_CHAIN_ID ?
                                                <img style={{height:25}} src={baseIco} alt="" />
                                                :
                                                <img style={{height:25}} src={erc20} alt="" />
                                                }
                                            </div>
                                            
                                        </td>
                                        <td>
                                            {process.env.REACT_APP_ETH_CHAIN_ID == item?.chainId ?
                                            <a href={`${process.env.REACT_APP_ethExplorer}/tx/${item?.transactionHash}`} target="_blank" rel="noopener noreferrer">
                                                {item?.transactionHash.substring(0, 15) + "..."}
                                            </a>
                                            :
                                            <a href={`${process.env.REACT_APP_baseExplorer}/tx/${item?.transactionHash}`} target="_blank" rel="noopener noreferrer">
                                                {item?.transactionHash.substring(0, 15) + "..."}
                                            </a>
                                            }
                                        </td>
                                        <td>{ item?.date ? dateFormtter.format(new Date(item?.date)) : "-"}</td>
                                        <td>
                                            <div>
                                                {item?.approval == "pending" &&
                                                <>
                                                <button className="btn btn-success m-1" onClick={() => updateApproval("approve", item?.transactionHash)}>Approve</button>
                                                <button className="btn btn-danger m-1" onClick={() => updateApproval("unapprove", item?.transactionHash)}>Reject</button>
                                                </>
                                                }
                                                {item?.approval == "approve" && 
                                                <button className="btn btn-success m-1" disabled>Approved</button>
                                                }
                                                {item?.approval == "unapprove" && 
                                                <button className="btn btn-danger m-1" disabled>Rejected</button>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-center">
                        <Stack spacing={2}>
                        <Pagination count={Math.ceil(noOfRequest/limit)} page={page} onChange={handleChange} />
                        </Stack>
                    </div>
            </div>
        </div>
    </div>
    </>
    )
}