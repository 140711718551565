import React,{useState,useEffect} from "react";
import Web3 from "web3";
import {Modal,Button} from 'react-bootstrap'
// import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider'

import bep20 from "../images/bep20.svg"
import erc20 from "../images/erc20.svg"
import baseIco from "../images/baseIco.png"

import logo from '../images/logo.png'
import metamask from '../images/metamask.png'
import walletConnect from '../images/walletConnect.png'
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import web3 from '../web3';
import { useDispatch } from 'react-redux';

import {NativeBrigdeAbi,nativeBridgeAddressForBsc,nativeErc20Abi,nativeErc20AddressForBsc, nativeErc20AddressForRoburna, nativeBridgeAddressForRoburna} from '../contractAbi'
import { bscId, ethId } from "../important";
import { loginUser, loginUserReq } from "../redux/ActionCreators";
import { serverPath } from "../baseUrl";
import { ToastContainer, toast } from 'react-toastify';

const checkIfUserExists = (address) => {console.log(address) ; return fetch(`${serverPath}/users/exists/${address}`).then(r => r.json())}

export const jwtValid = (token, address) => {
  return fetch(`${serverPath}/users/check-jwt/${address}`, {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${token}`
    }
  }).then(r => {
    console.log(r);
    if (r.ok){
      return r.json();
    }
    else{
      return {success: false};
    }
  });
}

const verifySignature = (signature, address) => {
  return fetch(`${serverPath}/users/login`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      signature,
      address
    })
  }).then(r => r.json())
}

function Navbar({getWeb3Info}) {

    const location = useLocation()
    const [isConnected,setisConnected] = useState(false);
    const [isConnecting,setisConnecting] = useState(false);
    const [activeAccount,setActiveAccount] = useState(null);
    const [activeBalance,setBalance] = useState(null);
    const [ID,setId] = useState(null);
    const [show, setShow] = useState(false);
 
    const [contract, setContract] = useState("");
    const [contract2, setContract2] = useState("");

    const dispatch = useDispatch();

    const notify = (response, types) =>
      types(response, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });

    useEffect(()=>{
      // if(!location.pathname.includes('admin')){
      //   logingHandler();
      // }
    },[])
    // mobile wllet provider
    // const providerOptions = new WalletConnectProvider({
    //   rpc: {
    //     8453: "wss://base-rpc.publicnode.com",
    //     80002: "https://rpc-amoy.polygon.technology",
    //     1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
    //     11155111: "https://ethereum-sepolia-rpc.publicnode.com",
    //     qrcode: true,
    //     pollingInterval: 12000,
    //   },
    //   chainId: 80002
    // });

    const getContract = async(web3,account,userBalance,id)=>{ 
      var contract1 = new web3.eth.Contract(NativeBrigdeAbi, nativeBridgeAddressForBsc );
      var contract2 = new web3.eth.Contract(nativeErc20Abi, nativeErc20AddressForBsc );
      var contract3 = new web3.eth.Contract(NativeBrigdeAbi, nativeBridgeAddressForRoburna );
      var contract4 = new web3.eth.Contract(nativeErc20Abi, nativeErc20AddressForRoburna );
      setContract(contract1)
      setContract2(contract2)
      getWeb3Info(web3, account,contract1,contract2,contract3,contract4,userBalance,id)
      // console.log(contract1);
      // console.log(contract2);
  }

  
    const walletAlreadyConnect = async ()=>{
      const provider = detectProvider();
      // const web3Mobile = new Web3(providerOptions);
      // console.log("web3Mobile");
    
      if(provider){
        const web3 =  new Web3(provider);
        let accounts = await web3.eth.getAccounts();
        // console.log("web account"+accounts);
        // console.log(web3);
  
         if(accounts.length > 0){
          let id =await web3.eth.getChainId()
          const balance = await web3.eth.getBalance(accounts[0])
          
          //  console.log(id);
          //  console.log(accounts);
        //    console.log(balance);
  
          if(id == bscId ||id == ethId){
            if(accounts[0] !== activeAccount){
              setActiveAccount(accounts[0])
              setBalance(balance)
              setId(id)
              setisConnected(true)
              getContract(web3,accounts[0],balance,id)


			//   create profile

            }
          }
          else {
            // alert("please switch network to BASE or Ethereum ")
            notify("please switch network to BASE or Ethereum ", toast.warning)
             setisConnected(false)
          }
        }
        // console.log(web3);
       }
    }
  
    const detectProvider =()=>{
      let provder;
      if(window.ethereum){
        provder=window.ethereum;
        // console.log("ethereum");
      }else if(window.web3){
        provder= window.web3.currentProvider;
        // console.log("web3");
      }else{
        // window.alert("no ethereum browser detected")
        notify("no ethereum browser detected", toast.warning)

      }
      return provder;
    }
  
    const logingHandler = async ()=>{
      try {       
        // const provider = detectProvider();
        // if(provider){
        //   if(provider !== window.ethereum){
        //     // console.error("dont have window.ethereum provider")
        //   }
        
        // // setisConnecting(true)
    
        // const web3 = new Web3(provider);
        // // console.log(await typeof web3.eth.getChainId());
    
        // if(await web3.eth.getChainId() == bscId || await web3.eth.getChainId() == ethId ){
        //   await provider.request({
        //     method:"eth_requestAccounts"
        //   });
        //   onLoging(provider)
        // }else{
        //   alert("You can only connect Ethereum and BSC")
        // }
        // console.log( parseInt(bscId), parseInt(window.ethereum.chainId) , parseInt(ethId))
        console.log(parseInt(window.ethereum.chainId),window.ethereum.chainId);
        if (!window.ethereum){
          alert('Install Metamask');
          notify("Install Metamask", toast.warning)
        }
        else if (parseInt(window.ethereum.chainId) !== parseInt(bscId) && parseInt(window.ethereum.chainId) !== parseInt(ethId)){
          // alert('Connect to ETHEREUM or BASE');
          notify("Connect to ETHEREUM or BASE", toast.warning)
        }
        else{
          web3.web3 = new Web3(window.ethereum);
          let accs = await window.ethereum.request({
            method: 'eth_requestAccounts'
          });
  
          if (accs.length > 0){
            let ad = accs[0].toLowerCase();
            let noToken = true;
            console.log(accs);
            let r = await checkIfUserExists(ad);
            if (localStorage.getItem(ad.toLowerCase())){
              let valid = await jwtValid(localStorage.getItem(ad.toLowerCase()), ad.toLowerCase());
              console.log("yuy:", valid);
              if (valid.success){
                valid.user.token = localStorage.getItem(ad.toLowerCase());
                dispatch(loginUser(valid.user));
                noToken = false;
                let b = await web3.web3.eth.getBalance(accs[0]);
                // setBalance(balance)
                setActiveAccount(accs[0]);
                setisConnected(true)
                setShow(false)
                setBalance(b);
                setId((parseInt(window.ethereum.chainId)).toString());
                getContract(web3.web3,accs[0],b,(parseInt(window.ethereum.chainId)).toString());
                localStorage.setItem("connectBy","web")
              }
              else{
                localStorage.removeItem(ad);
              }
            }
            
            if (noToken){
              let signature, signed = true;
              try{
                signature = await web3.web3.eth.personal.sign(
                  `I am signing my one-time nonce: ${r.user.nonce}`,
                  accs[0],
                  ''
                );  
              }
              catch(e){
                console.log(e);
                signed = false;
                // alert('Need to sign signature to continue.');
                notify("Need to sign signature to continue.", toast.warning)
  
              }
              if (signed){
                let res = await verifySignature(signature, ad);
                if (res.success){
                  r.user.token = res.token;
                  dispatch(loginUser(r.user));
                  setActiveAccount(accs[0]);
                  let b = await web3.web3.eth.getBalance(accs[0]);
                  setisConnected(true)
                  setShow(false)
                  setBalance(b);
                  setId((parseInt(window.ethereum.chainId)).toString());
                  getContract(web3.web3,accs[0],b,(parseInt(window.ethereum.chainId)).toString()) 
                  localStorage.setItem("connectBy","web")
                }
                else{
                  // alert('Invalid Signature');
                  notify("Invalid Signature", toast.warning)
                }
              } 
            }
          }
        }
        // setisConnecting(false)
      } catch (error) {
        // notify("error.message", toast.warning)
        console.log(error);
        
      }
    }
  
    const onLoging = async(provider)=>{
      const web3 = new Web3(provider);
      // const accounts =  web3.eth.getAccounts();
      let id =await web3.eth.getChainId()
      const accounts = await web3.eth.getAccounts();
      const balance = await web3.eth.getBalance(accounts[0])
      // console.log(accounts);
      // console.log(accounts[0].length);
      if(accounts.length ===0){
        // console.log("Please Connect metamask");
      }
      else if(accounts[0] !== activeAccount){
        setActiveAccount(accounts[0])
        setBalance(balance)
        setisConnected(true)
        setId(id)
        setShow(false)
        getContract(web3,accounts[0],balance,id)


        // console.log("setIsConnected true");
      }
    }
  
    useEffect(()=>{
      // if(isConnected ){
      // }
        window.ethereum.on('networkChanged', function (ID) {
          console.log("useEffect2");
          // Time to reload your interface with accounts[0]!
          logingHandler()
        });
    },[])
  

  async function logoutWC(){
    try {    
      let checkConnectedBy = localStorage.getItem("connectBy")
      if(checkConnectedBy == "mobile") {
  
        const providerOptions = await EthereumProvider.init({
          projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
          chains: [parseInt(process.env.REACT_APP_CHAIN_ID)], // REQUIRED chain ids
          showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
        });
    
        await providerOptions.enable()
        const web3 = new Web3(providerOptions);
        const account = await web3.eth.getAccounts();
      
        if (account.length > 0){
          await providerOptions.disconnect();
          // console.log("wallet Disconnect"); 
            setActiveAccount(null)
            setBalance(null)
            setisConnected(false)
            setId(null)
        }
        else{
        }
  
      }else{
        setActiveAccount(null)
        setBalance(null)
        setisConnected(false)
        setId(null)
      }
    } catch (error) {
      console.log(error);
      notify(error.message, toast.warning)
    }
  }
  
  const handleWalletConnect=async ()=>{
    try {     
      const providerOptions = await EthereumProvider.init({
          projectId: process.env.REACT_APP_WC_PROJECT_ID, // REQUIRED your projectId
          chains: [parseInt(process.env.REACT_APP_ETH_CHAIN_ID)], // REQUIRED chain ids
          showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
        });
        setShow(false)
    var connected = await providerOptions.enable()
    // console.log(connected);

     web3.web3 = new Web3(providerOptions);
    // web3.web3 = web3wwwwww;
    const accs = await web3.web3.eth.getAccounts();
  
    // console.log(web3.web3);
    console.log(accs);
    // console.log(activeAccount);
    if (accs.length > 0){
      const id =await web3.web3.eth.getChainId()
      console.log(id);
      if (parseInt(id) !== parseInt(bscId) && parseInt(id) !== parseInt(ethId)){
        // alert('Connect to ETHEREUM or BASE');
        notify("Connect to ETHEREUM or BASE", toast.warning)
        return
      }
      let ad = accs[0].toLowerCase();
      let noToken = true;
      console.log(accs);
      let r = await checkIfUserExists(ad);
      if (localStorage.getItem(ad.toLowerCase())){
        let valid = await jwtValid(localStorage.getItem(ad.toLowerCase()), ad.toLowerCase());
        console.log("yuy:", valid);
        if (valid.success){
          valid.user.token = localStorage.getItem(ad.toLowerCase());
          dispatch(loginUser(valid.user));
          noToken = false;
          let b = await web3.web3.eth.getBalance(accs[0]);
          // setBalance(balance)
          setActiveAccount(accs[0]);
          setisConnected(true)
          setShow(false)
          setBalance(b);
          setId((parseInt(id)).toString());
          getContract(web3.web3,accs[0],b,(parseInt(id)).toString());
          localStorage.setItem("connectBy","mobile")
        }
        else{
          localStorage.removeItem(ad);
        }
      }
      
      if (noToken){
        let signature, signed = true;
        try{
          signature = await web3.web3.eth.personal.sign(
            `I am signing my one-time nonce: ${r.user.nonce}`,
            accs[0],
            ''
          );  
        }
        catch(e){
          console.log(e);
          signed = false;
          // alert('Need to sign signature to continue.');
          notify("Need to sign signature to continue.", toast.warning)

        }
        if (signed){
          let res = await verifySignature(signature, ad);
          if (res.success){
            r.user.token = res.token;
            dispatch(loginUser(r.user));
            setActiveAccount(accs[0]);
            let b = await web3.web3.eth.getBalance(accs[0]);
            setisConnected(true)
            setShow(false)
            setBalance(b);
            setId((parseInt(id)).toString());
            getContract(web3.web3,accs[0],b,(parseInt(id)).toString()) 
            localStorage.setItem("connectBy","mobile")
          }
          else{
            // alert('Invalid Signature');
            notify("Invalid Signature", toast.warning)
          }
        } 
      }
    }
    } catch (error) {
      console.log(error);
      notify(error.message, toast.warning)
    }
  }  

    return(
    <nav className={location.pathname.includes('admin') ? "navbar navbar-expand-lg navbar-light bgGray ":"navbar navbar-expand-lg navbar-light "} >
        <Link className="navbar-brand" to="/" > <img src={logo} alt="logo" /> </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {/* <li className="nav-item active">
              <Link className="nav-link" to="connection">Collection </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" to="marketplace">Marketplace </Link>
            </li> */}
          </ul>
            {/* <button className="btn btn-outline-dark my-2 my-sm-0" type="submit">Connect Wallet</button> */}
                  {/* {activeAccount && 
            <>
            <Link className="mr-2" to="create">Create</Link>
            <Link className="mr-2" to="profile">Profile</Link>
            </>
            } */}
			{!isConnected && 
      <>
      {!location.pathname.includes('admin') &&
            <button className='btn btn-primary walletBtn' onClick={() => setShow(true)}>
             Connect Wallet
            </button>
      }
      </>
          }
          {isConnected && 
          <>
            {ID == bscId?
            <button className="btn balanceBtn"><img className="bsc" src={baseIco} alt="bnb" /> 
              {activeBalance?(activeBalance / 1000000000000000000).toFixed(3) : 0}
              <span> ETH</span>
            </button> 
            :
            <button className="btn balanceBtn"><img  className="eth"src={erc20} alt="bnb" />
              {activeBalance?(activeBalance / 1000000000000000000).toFixed(3) : 0}
              <span> ETH</span>
            </button> 
            }
            <button className='btn btn-primary walletBtn' onClick={logoutWC} >
             {activeAccount.slice(0,5)}...{activeAccount.slice(38,activeAccount.length)}
             <div className="tooltip1">Logout</div>
            </button>
          </>
          }
        </div>

        
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            please connect your web3 wallet
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
  
            <div className="d-flex connect-wallet-BtnDiv" style={{justifyContent:"center"}}>

            <div className="walletModalBtn">
              <div className="imgDIv">
              <img src={metamask} alt="connect wallet" />
              </div>
              <button type='button' className='btn btn-primary mr-3' style={{display:"inline-block"}} onClick={logingHandler}>
              Connect Metamask
              </button>
            </div>
            <div className="walletModalBtn ml-2">
              <div className="imgDIv">
                <img src={walletConnect} alt="wallet connect" />
              </div>
                <button className='btn btn-primary' onClick={handleWalletConnect} >
                WalletConnect 
                </button>
            </div>
          
            </div>
          </Modal.Body>
        </Modal>
        <ToastContainer style={{ width: "max-content" }} />

    </nav>
    )
}


  
  
export {Navbar};
