import React, { useState } from 'react'
import { useEffect } from 'react';
import { serverPath } from '../../baseUrl';
import web3 from '../../web3';

import axios from 'axios';
import { nativeBridgeAddressForRoburna, nativeErc20Abi, nativeErc20AddressForBsc, nativeErc20AddressForRoburna } from '../../contractAbi';
import Web3 from 'web3';
import { Button, Form, Modal } from 'react-bootstrap';

export const StatsComponent = () => {
    const [statsData,setStatsData] = useState({});
    const [baseSupply,setBaseSupply] = useState(0);
    const [ethSupply,setethSupply] = useState(0);
    const [maxSupply,setMaxSupply] = useState(0);
    const [show, setShow] = useState(false);
    const [formSupply,setFormSupply] = useState(0);

    async function getData() {
        console.log(sessionStorage.getItem("token"));
        
        await axios.get(`${serverPath}/admin/get-stats`, {
            headers: {
                'Content-Type':'application/json',
                "musto-access-token": sessionStorage.getItem("token").toString()
            }
        })
            .then(res => {
                console.log(res.data);
                setStatsData(res?.data?.data || [])
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getSupply = async() => {
        try {
            let web3Base = new Web3(process.env.REACT_APP_baseRpc);
            let web3Eth = new Web3(process.env.REACT_APP_ethRpc);

            let baseContract =  new web3Base.eth.Contract(nativeErc20Abi, nativeErc20AddressForBsc)
            let EthContract =  new web3Eth.eth.Contract(nativeErc20Abi, nativeErc20AddressForRoburna)

            var resultBase = await baseContract.methods.totalSupply().call()
            var resultEth = await EthContract.methods.balanceOf(nativeBridgeAddressForRoburna).call()
            console.log(resultBase);
            console.log(resultEth);
            
            if(resultBase) {
                setBaseSupply(resultBase / 10 ** 18)
            }
            if(resultEth) {
                setethSupply(resultEth / 10 ** 18)
            }
            var resultMax = await baseContract.methods.maxSupply().call()
            if(resultMax) {
                setMaxSupply(resultMax / 10 ** 18)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSetSupply = async()=> {
        try {      
            if(!formSupply) {
                alert("Please enter value");
                return
            }
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            console.log(accounts[0]);
            const web3Base = new Web3(window.ethereum)
            let baseContract =  new web3Base.eth.Contract(nativeErc20Abi, nativeErc20AddressForBsc)
            var result = await baseContract.methods.setMaxSupply(formSupply).send({ from: accounts[0] })
            console.log(result);
            if(result?.blockHash){
                alert("Supply updated.")
                setShow(false)
            }
        } catch (error) {
            console.log(error);
            alert("Something went wrong.")
            
        }

        
    }

    useEffect(() => {
        getData()
        getSupply()
    }, [])
    
  return (
    <div className="statsCardMain">
                <div className="row">
                    <div className="col-md-3  mb-4">
                        <div className="statsCard">
                            <div className="ico">
                                <i className="fas fa-coins"></i>
                            </div>
                            <div className="content">
                                <div className="title">Total Mint in Base</div>
                                <div className="value">{baseSupply || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-4">
                        <div className="statsCard">
                            <div className="ico">
                                <i className="fas fa-coins"></i>
                            </div>
                            <div className="content">
                                <div className="title">Balance of Eth</div>
                                <div className="value">{ethSupply || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-4">
                        <div className="statsCard">
                            <div className="ico">
                                <i className="far fa-clock"></i>
                            </div>
                            <div className="content">
                                <div className="title">Total Pendings claim for Eth</div>
                                <div className="value">{statsData?.pendingBase}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-4">
                        <div className="statsCard">
                            <div className="ico">
                                <i className="far fa-clock"></i>
                            </div>
                            <div className="content">
                                <div className="title">Total Pendings claim for Base</div>
                                <div className="value">{statsData?.pendingEth}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-4">
                        <div className="statsCard">
                            <div className="ico">
                                <i className="far fa-check-circle"></i>
                            </div>
                            <div className="content">
                                <div className="title">Total Approve Base</div>
                                <div className="value">{statsData?.approveBase || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-4">
                        <div className="statsCard">
                            <div className="ico">
                                <i className="far fa-check-circle"></i>
                            </div>
                            <div className="content">
                                <div className="title">Total Approve Eth</div>
                                <div className="value">{statsData?.approveEth || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-4">
                        <div className="statsCard">
                            <div className="ico">
                                <i className="fas fa-wallet"></i>
                            </div>
                            <div className="content">
                                <div className="title">Max Supply</div>
                                <div className="value">{maxSupply || 0}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3  mb-4">
                        <div className="setMaxCard" onClick={()=>setShow(true)}>
                            Set Max Supply
                        </div>
                    </div>
                </div>

                <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
            Set Max Supply
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="maxsupplyForm">
                <Form onSubmit={(e) => e.preventDefault()} >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter Amount</Form.Label>
                        <Form.Control type="text" placeholder="Enter amount" onChange={(e) => setFormSupply(e.target.value)} required />
                    </Form.Group>
                    <Button className="sBtn w-100" variant="primary" type="button" onClick={handleSetSupply} >Submit</Button>
                </Form>
            </div>
  
         
          
          </Modal.Body>
        </Modal>
            </div>
  )
}
