import { useState, useEffect } from "react";
import axios from "axios"
import Web3 from "web3"

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { serverPath } from "../../baseUrl";
import { Buffer } from 'buffer';




export default function Login() {
    const [stage, setStage] = useState(1);
    const [myForm, setMyForm] = useState({
        email: "",
        pass: ""
    });

    const handleStateOne = async (e) => {
        if (myForm.email !== "" && myForm.pass !== "") {
            setStage(2)
        } else {
            alert("Fill all fields")
        }
    }

    const signWallet = async (e) => {
        if (typeof window.ethereum === 'undefined') {
            alert('MetaMask is not installed!');
        }
        const myWeb3 = await new Web3(window.ethereum)
        // ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        const customNonce = Date.now();
        const exampleMessage = `I'm signing as an admin with nonce ${customNonce}`;

        try {
            const from = accounts[0];
            const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
            const sign = await window.ethereum.request({
                method: 'personal_sign',
                params: [msg, from, 'Example password'],
            });
            console.log(sign);
            handleSubmit(sign, customNonce)

        } catch (err) {
            console.error(err);
        }
    }

    const handleSubmit = async (signature, customNonce) => {
        console.log(myForm);

        try {
            await axios.post(`${serverPath}/admin/login`, {
                ...myForm,
                signature,
                nonce: customNonce
            })
                .then(res => {
                    if (res.data.token !== "" && (res.data.user).length > 0) {
                        console.log(res.data);
                        sessionStorage.setItem("token", res.data.token)
                        window.location.href = "/admin/claim-tx"

                    } else {
                        alert("invalid creddentials")
                    }

                })
                .catch(err => {
                    console.log(err);
                    alert("invalid creddentials")
                    setStage(1)
                })
        } catch (error) {
            console.log(error);
            alert("invalid creddentials")
            setStage(1)
            
        }
    }


    useEffect(() => {
        const session = sessionStorage.getItem("token")
        if (session !== null) {
            window.location.href = "/admin/claim-tx"
        }

    }, [])



    return <div style={{ width: "97vw", height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {
            stage === 1 ?
                <Form onSubmit={(e) => e.preventDefault()} className="adminLoginForm" >
                    <h3 className="formHead text-center">Admin Login</h3>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" onChange={(e) => setMyForm({ ...myForm, email: e.target.value })} required />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={(e) => setMyForm({ ...myForm, pass: e.target.value })} required />
                    </Form.Group>
                    <Button className="sBtn w-100" variant="primary" type="button" onClick={() => handleStateOne()}>Submit</Button>
                </Form>
                :
                <Form>
                    <Button className="sBtn" variant="primary" onClick={signWallet}>Verify Authority</Button>
                </Form>

        }
    </div>
}