import React,{ useState,useEffect,useRef } from "react";
import { BrowserRouter, BrowserRouter as Router,Route,Switch,Redirect } from "react-router-dom";
import { Home } from "../component/home";
import Login from "../component/admin-panel/login";
import ClaimTX from "../component/admin-panel/claimTX";

import { Navbar } from '../component/navbar';



export const AppRouter=()=>{
    var [contract1,setContract1]=useState(null);
    var [contract2,setContract2]=useState(null);
    var [contract3,setContract3]=useState(null);
    var [contract4,setContract4]=useState(null);
    var [marketContract,setmarketContract]=useState(null);
    var [account,setAccount]=useState(null);
    var [balance,setBalance]=useState(null);
    var [chainId,setId]=useState(null);
    var [Web3,setWeb3]=useState(null);

   const getWeb3Info=(web3,account,contract,contract2,contract3, contract4,userBalance,id)=>{
    setAccount(account)
    setContract1(contract)
    setContract2(contract2)
    setContract3(contract3)
    setContract4(contract4)
    setBalance(userBalance)
    setWeb3(web3)
    setId(id)
   }

   const changeNetwork = async(id) => {
    try {     
        let resp=await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: id }], // chainId must be in hexadecimal numbers
        });
        console.log(resp);
    } catch (error) {
        console.log(error);
        
    }
    
   }
    return(
        <>
        <BrowserRouter>
            <Navbar getWeb3Info={getWeb3Info} />
            <Route exact path='/' component={() => <Home changeNetwork={changeNetwork} web3={Web3} chainId={chainId} nativeContractBsc={contract1} erc20contractBsc={contract2} nativeContractRoburna={contract3} erc20contractRoburna={contract4} account={account} balance={balance} /> }  />
            <Route exact path='/admin/Login' component={() => <Login web3={Web3} chainId={chainId} nativeContractBsc={contract1} erc20contractBsc={contract2} nativeContractRoburna={contract3} erc20contractRoburna={contract4} account={account} balance={balance} /> }  />
            <Route exact path='/admin/claim-tx' component={() => <ClaimTX web3={Web3} chainId={chainId} nativeContractBsc={contract1} erc20contractBsc={contract2} nativeContractRoburna={contract3} erc20contractRoburna={contract4} account={account} balance={balance} /> }  />
        </BrowserRouter>
        </>
    )
}
