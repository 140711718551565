import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Web3 from "web3"
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TenupIcon from "../images/Tenup-Icon.png"
import baseIco from "../images/baseIco.png"
import erc20 from "../images/erc20.svg"
import convert from "../images/convert.svg"
import failicon from "../images/failicon.svg"
import completeIco from "../images/completeIco.svg"
import complete from "../images/complete.svg"
import { nativeBridgeAddressForBsc, nativeBridgeAddressForRoburna, nativeErc20Abi, nativeErc20AddressForBsc, privateKey } from "../contractAbi";
import axios from "axios";
import { BigNumber } from 'bignumber.js';
import { useSelector } from 'react-redux';

import { bscId, ethId } from "../important";
import { serverPath } from "../baseUrl";

export const Home = ({ changeNetwork, web3, chainId, nativeContractBsc, erc20contractBsc, nativeContractRoburna, erc20contractRoburna, account, balance }) => {
    var [fromToSwitch, setFromToSwitch] = useState(false)
    var [userData, setUserData] = useState({
        amount: "",
        address: "",
        from: "BASE",
        to: "erc20",
    })
    var [userBalance, setBalance] = useState(0)
    const [loader, setLoader] = useState(false)
    const [claimLoader, setClaimLoader] = useState(false)

    const [showClaim, setShowClaim] = useState(false)
    const [claimData, setClaimData] = useState([])

    const [showSwitch, setShowSwitch] = useState(false);
    const [showTxFail, setShowTxFail] = useState(false);
    const [showTxComplete, setShowTxComplete] = useState(false);
    const [showClaimComplete, setShowClaimComplete] = useState(false);
    const [currentTxHash, setCurrentTxHash] = useState("");

    const handleCloseTxFail = () => setShowTxFail(false);
    const handleCloseTxComplete = () => setShowTxComplete(false);
    const handleCloseClaimComplete = () => setShowClaimComplete(false);

    const handleClose = () => setShowSwitch(false);
    const handleShow = () => setShowSwitch(true);

    const authedUser = useSelector(s => s.authedUser);

    // notification setting
    const notify = (response, types) =>
        types(response, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });


    const createFormShow = (e) => {
        e.preventDefault()
        let create = document.getElementById('create')
        let claim = document.getElementById('claim')
        create.style.background = 'linear-gradient(180deg, #2891d9, #08a7df)'
        claim.style.background = 'transparent'
        create.style.color = '#fff'
        claim.style.color = '#000'
        setShowClaim(false)
    }
    const claimFormShow = (e) => {
        e.preventDefault()
        let create = document.getElementById('create')
        let claim = document.getElementById('claim')
        create.style.background = 'transparent'
        claim.style.background = 'linear-gradient(180deg, #2891d9, #08a7df)'
        create.style.color = '#000'
        claim.style.color = '#fff'
        setShowClaim(true)
    }
    const handleInterchange = (e) => {
        var intImg = document.getElementById('intImg')
        if (fromToSwitch) {
            
            intImg.style.transform="rotate(0deg)"
            // setFromToSwitch(false)
            setUserData({ ...userData, from: "BASE", to: "erc20" })
            changeNetwork(`0x${new Number(process.env.REACT_APP_CHAIN_ID).toString(16)}`)
        } else {
            intImg.style.transform="rotate(180deg)"
            // setFromToSwitch(true)
            setUserData({ ...userData, from: "erc20 ", to: "BASE" })
            changeNetwork(`0x${new Number(process.env.REACT_APP_ETH_CHAIN_ID).toString(16)}`)
        }
    }

    useEffect(() => {
        // var intImg = document.getElementById('intImg')

        // console.log(chainId);
        // console.log(nativeContractBsc);
        // console.log(erc20contractBsc);
        // console.log(nativeContractRoburna);
        // console.log(erc20contractRoburna);
        // console.log(account);
        if(account) {

            if (chainId == bscId) {
                setFromToSwitch(false)
                //  intImg.style.transform="rotate(0deg)"
                setUserData({ ...userData, from: "BASE", to: "erc20" })
            }
            else {
                setFromToSwitch(true)
                // intImg.style.transform="rotate(180deg)"
                setUserData({ ...userData, from: "erc20 ", to: "BASE" })
            }
            if (account) {
                setUserData({ ...userData, address: account })
            }
        }


    }, [account])

    useEffect(() => {
        getUserTokenBalance()
    }, [account])

    const getUserTokenBalance = async () => {
        try {
            if (account) {
                if (chainId == bscId) {
                    let bscContract =  new web3.eth.Contract(nativeErc20Abi, nativeErc20AddressForBsc)
                    console.log(bscContract);
                    console.log(account);
                    var result = await bscContract.methods.balanceOf(account).call()
                    console.log("balance");
                    console.log(chainId);
                    console.log(bscId);
                    console.log(result);
                    setBalance(result ? result / 1000000000000000000 : 0)
                } else if (chainId == ethId) {
                    var result = await erc20contractRoburna.methods.balanceOf(account).call()
                    // console.log(result);
                    setBalance(result ? result / 1000000000000000000 : 0)
                }
            }
        } catch {
            // console.log('====================================');
            // console.log("maybe user enter wrong address");
            // console.log('====================================');
        }
    }

    const handleMax = () => {
        setUserData({ ...userData, amount: userBalance.toFixed(2) })
    }

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        try {     
            if (account) {
                if (userData.amount && userData.address) {
                    if(userData.amount > 10000000 || userData.amount < 0.1){
                        notify("Invalid amount, you can deposit from 0.1 to 1000000", toast.warning)
                        return
                    }
                    if (userData.address.length != 42) {
    
                        notify("Please insert correct wallet address", toast.warning)
                    } else {
                        if (userBalance >= userData.amount && userData.amount > 0) {
                            // console.log(userBalance);
                            // console.log(userData);
                            if (chainId == bscId) {
                                handleInteractionBsc()
                                console.log(chainId);
                            } else if (chainId == ethId) {
                                console.log(chainId);
                                handleInteractionRoburna()
                            }
                        } else {
                            notify("You do not have enough token", toast.warning)
    
                        }
                    }
    
                } else {
                    notify("Please fill all fields", toast.warning)
                }
            } else {
                notify("Please connect your wallet", toast.warning)
            }
        } catch (error) {
            notify("Something went wrong", toast.warning)
            setLoader(false)
        }

    }

    const handleInteractionBsc = async () => {
        try {
            setLoader(true)
    
            let tokenAmount = parseFloat(userData.amount) * 1000000000000000000;
            tokenAmount = new BigNumber(tokenAmount);
            let allow = await erc20contractBsc.methods.allowance(account, nativeBridgeAddressForBsc).call()
            // console.log('====================================');
            // console.log(allow);
            // console.log(userData.amount );
            // console.log('====================================');
    
            if (allow / 1000000000000000000 >= userData.amount) {
                handleDepositBsc()
            } else {
                erc20contractBsc.methods.approve(nativeBridgeAddressForBsc, tokenAmount.toFixed()).send({ from: account })
                    .then(async (res) => {
                        // console.log(".then");
                        // console.log(res);
    
                        if (res.transactionHash) {
                            setLoader(false)
                            handleDepositBsc()
                        }
    
                    }).catch((res) => {
                        //   console.log("catch");
                          console.log(res);
                        setLoader(false)
                        setShowTxFail(true)
    
    
                    })
            }
            
        } catch (error) {
            notify("Something went wrong", toast.warning)
            setLoader(false)
        }

    }

    const handleInteractionRoburna = async () => {
        try {     
            setLoader(true)
    
            let tokenAmount = parseFloat(userData.amount) * 1000000000000000000;
            tokenAmount = new BigNumber(tokenAmount);
            let allow = await erc20contractRoburna.methods.allowance(account, nativeBridgeAddressForRoburna).call()
            // console.log('====================================');
            // console.log(allow);
            // console.log(userData.amount );
            // console.log('====================================');
    
            if (allow / 1000000000000000000 >= userData.amount) {
                handleDepositRoburna()
            } else {
                erc20contractRoburna.methods.approve(nativeBridgeAddressForRoburna, tokenAmount.toFixed()).send({ from: account })
                    .then(async (res) => {
                        // console.log(".then");
                        console.log(res);
    
                        if (res.transactionHash) {
                            setLoader(false)
                            handleDepositRoburna()
                        }
    
                    }).catch((res) => {
                        //   console.log("catch");
                          console.log(res);
                        setLoader(false)
                        setShowTxFail(true)
    
                    })
            }
        } catch (error) {
            notify("Something went wrong", toast.warning)
            setLoader(false)
        }

    }

    const handleDepositBsc = async () => {

        console.log('====================================');
        console.log(process.env.REACT_APP_PROGRAM_KEY);
        console.log('====================================');
        setLoader(true)
        let tokenAmount = parseFloat(userData.amount) * 1000000000000000000;
        tokenAmount = new BigNumber(tokenAmount);
        try {

            var result = await nativeContractBsc.methods.depositTokens(tokenAmount.toFixed(), userData.address).send({ from: account })
                .on('transactionHash', (hash) => {
                    let sendData = {
                        txCustomHash: "",
                        txId: "",
                        transactionHash: hash,
                        receiver: userData.address,
                        from: account,
                        status: 0,
                        amount: userData.amount,
                        chainId: chainId,
                    }

                    //console.log(hash);
                    pendingTxHandle('POST', '/deposit', hash, sendData)
                });
            // console.log(result);
            // console.log(result);

            if (result.events) {
                getUserTokenBalance()
                let sendData = {
                    txCustomHash: result.events.TokenDeposit.returnValues.txHash,
                    txId: result.events.TokenDeposit.returnValues.transactionID,
                    transactionHash: result.transactionHash,
                    from: result.from,
                    receiver: userData.address,
                    status: 0,
                    amount: userData.amount,
                    chainId: chainId,
                }
                console.log(authedUser);

                await axios.post(`${serverPath}/claimer`, sendData, {
                    // headers:{
                    //     Authorization:process.env.REACT_APP_PROGRAM_KEY
                    // }
                    headers: {
                        'apiKey': process.env.REACT_APP_PROGRAM_KEY,
                        'Authorization': `Bearer ${authedUser.authedUser.token}`
                    }
                })
                    .then((res) => {
                        notify("Deposited", toast.success)
                        //console.log(res);
                        setLoader(false)
                        setShowTxComplete(true)
                        setCurrentTxHash(result?.transactionHash)
                        getClaimData()

                    })
                    .catch((res) => {
                        console.log(res);
                        setLoader(false)
                    })
            }
        } catch(error) {
            console.log(error);
            
            setLoader(false)
            notify("You need to confirm the transaction to deposit", toast.warning)
            setShowTxFail(true)

        }

    }

    const pendingTxHandle = async (method, endpoint, txid, body) => {
        //console.log(`${serverPath}/pendingtx`);
        let sendObj = {
            method: method,
            endpoint: endpoint,
            txid: txid,
            data: JSON.stringify(body),
            network: chainId == bscId ? "base" : "eth"

        }
        await axios.post(`${serverPath}/pendingtx`, sendObj)
            .then((res) => {
                //console.log(res);  
            })
            .catch((res) => {
                //console.log(res);  
            })

    };

    const handleDepositRoburna = async () => {
        setLoader(true)
        let tokenAmount = parseFloat(userData.amount) * 1000000000000000000;
        tokenAmount = new BigNumber(tokenAmount);
        try {

            var result = await nativeContractRoburna.methods.depositTokens(tokenAmount.toFixed(), userData.address).send({ from: account })
                .on('transactionHash', (hash) => {
                    let sendData = {
                        txCustomHash: "",
                        txId: "",
                        transactionHash: hash,
                        from: account,
                        receiver: userData.address,
                        status: 0,
                        amount: userData.amount,
                        chainId: chainId,
                    }

                    console.log(hash);
                    pendingTxHandle('POST', '/deposit', hash, sendData)
                });

            console.log(result);
            // console.log(result);

            if (result.events) {
                getUserTokenBalance()
                let sendData = {
                    txCustomHash: result.events.TokenDeposit.returnValues.txHash,
                    txId: result.events.TokenDeposit.returnValues.transactionID,
                    transactionHash: result.transactionHash,
                    from: result.from,
                    receiver: userData.address,
                    status: 0,
                    amount: userData.amount,
                    chainId: chainId,
                }

                await axios.post(`${serverPath}/claimer`, sendData, {
                    // headers:{
                    //     Authorization:process.env.REACT_APP_PROGRAM_KEY
                    // }
                    headers: {
                        'apiKey': process.env.REACT_APP_PROGRAM_KEY,
                        'Authorization': `Bearer ${authedUser.authedUser.token}`
                    }
                })
                    .then((res) => {
                        notify("Deposited", toast.success)
                        //console.log(res);
                        console.log(res);
                        // notify("saved",toast.success) 
                        setLoader(false)
                        setShowTxComplete(true)
                        setCurrentTxHash(result?.transactionHash)
                        getClaimData()
                    })
                    .catch((res) => {
                        console.log(res);
                        setLoader(false)
                    })
            }
        } catch(error) {
            console.log(error);
            setLoader(false)
            notify("You need to confirm the transaction to deposit", toast.warning)
            setShowTxFail(true)

        }

    }


    const getClaimData = async () => {
        var headers = new Headers();

        if (account) {
            await axios.get(`${serverPath}/claimables?address=${account}&headers=${headers}`)
                .then((res) => {
                    // console.log("deposit data");
                    console.log(res.data);
                    setClaimData(res.data)

                })
                .catch((res) => {
                    console.log(res);
                })
        }
    }

    useEffect(() => {
        getClaimData()
    }, [account])



    const pendingWithdrawTxHandle = async (method, endpoint, txid, body) => {
        //console.log(`${serverPath}/pendingtx`);
        let sendObj = {
            method: method,
            endpoint: endpoint,
            data: JSON.stringify(body),
            txid: txid,
            network: chainId == bscId ? "base" : "eth"

        }
        await axios.post(`${serverPath}/pendingtx`, sendObj)
            .then((res) => {
                //console.log(res);  
            })
            .catch((res) => {
                //console.log(res);  
            })

    };
    const claimToEth = async (item) => {

        if (chainId == ethId) {
            setClaimLoader(true)
            try {

                const isApproved = await axios.get(`${serverPath}/check-approval/${item.transactionHash}`)
                    .then(res => {
                        // console.log(res.data);
                        if (res.data.error) {
                            notify(res.data.error, toast.error)
                            setClaimLoader(false)
                            return false;
                        }
                        return true;
                    })
                    .catch(err => {
                        console.log(err);
                        setClaimLoader(false)
                        return false;
                    })

                    // alert(item.transactionHash)
                if (isApproved) {
                    await axios.put(`${serverPath}/redeemVerifier`, {
                        transactionHash: item.transactionHash,
                        address: authedUser.authedUser.address?.toLowerCase()
                    }, {
                        headers: {
                            'apiKey': process.env.REACT_APP_PROGRAM_KEY,
                            'Authorization': `Bearer ${authedUser.authedUser.token}`
                        }
                    })
                        .then(async (res) => {
                            if (res.data) {
                                let signature = res.data.signature
                                let txCustomHash = res.data.txCustomHash
                                let txId = res.data.txId
                                var withdrawHash;
                                var result = await nativeContractRoburna.methods.withdrawTokens(txCustomHash, txId, signature).send({ from: account })
                                    .on('transactionHash', (hash) => {
                                        let sendData = {
                                            transactionHash: item.transactionHash,
                                        }
                                        //console.log(hash);
                                        withdrawHash = hash;
                                        pendingWithdrawTxHandle('PUT', '/withdraw', hash, sendData)
                                    });

                                // console.log(result);
                                if (result.blockHash) {
                                    saveWithDrawToDb(item.transactionHash, result, withdrawHash)
                                }
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            setClaimLoader(false)
                            setShowTxFail(true)
                            // console.log("Error in signature");

                        })

                }

            } catch {
                notify("Something went wrong check your network", toast.warning)
                setClaimLoader(false)
            }
        } else {
            // notify("Please switch to ETH network",toast.warning)     
            setShowSwitch(true)

        }

    }
    const claimToBSC = async (item) => {

        if (chainId == bscId) {
            setClaimLoader(true)
            try {

                await axios.put(`${serverPath}/redeemVerifier`, {
                    transactionHash: item.transactionHash,
                    address: authedUser.authedUser.address?.toLowerCase()
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'apiKey': process.env.REACT_APP_PROGRAM_KEY,
                        'Authorization': `Bearer ${authedUser.authedUser.token}`,
                    }
                })
                    .then(async (res) => {
                        //console.log("sign withhdraw");
                        //console.log(res.data);
                        if (res.data) {
                            let signature = res.data.signature
                            let txCustomHash = res.data.txCustomHash
                            let txId = res.data.txId

                            var withdrawHash;
                            console.log("txCustomHash-----------------")
                            console.log(txCustomHash)
                            console.log(txId)
                            console.log(signature)
                            var result = await nativeContractBsc.methods.withdrawTokens(txCustomHash, txId, signature).send({ from: account })
                                .on('transactionHash', (hash) => {
                                    //console.log(hash);
                                    let sendData = {
                                        transactionHash: item.transactionHash,
                                    }
                                    withdrawHash = hash;

                                    pendingWithdrawTxHandle('PUT', '/withdraw', hash, sendData)
                                });

                            // console.log(result);
                            if (result.blockHash) {
                                saveWithDrawToDb(item.transactionHash, result, withdrawHash)
                            }
                        }
                    })
                    .catch((res) => {
                        console.log(res);
                        setClaimLoader(false)
                        setShowTxFail(true)
                        // console.log("error in signature");

                    })

            } catch {
                notify("Something went wrong check your network", toast.warning)
                setClaimLoader(false)
            }
        } else {
            // notify("Please switch to BSC network",toast.warning)   
            setShowSwitch(true)

        }

    }

    const saveWithDrawToDb = async (hash, result, withdrawHash) => {
        var headers = new Headers();

        await axios.put(`${serverPath}/redeemUpdate`, {
            transactionHash: hash,
            withdrawHash: withdrawHash
        }, {
            headers: {
                'Content-Type': 'application/json',
                'apiKey': process.env.REACT_APP_PROGRAM_KEY,
                'Authorization': `Bearer ${authedUser.authedUser.token}`,
            }
        })
            .then((res) => {
                // console.log(res);
                // notify("withdraw success ",toast.success) 
                if(res.success == false){
                    
                    notify(res.message,toast.success) 
                    setLoader(false)
                }else{
                    getUserTokenBalance()
                    setClaimLoader(false)
                    setShowClaimComplete(true)
                    setCurrentTxHash(result?.transactionHash)
                    getClaimData()
                }

            })
            .catch((res) => {
                // console.log(res);
                setLoader(false)

            })
    }

    console.log("authedUser");
    console.log(authedUser);
    


    return (
        <>
            {/* {props.account?
        <Link  to="/create"  className="btn btn-dark creatBtn">Create</Link>
        :
        <button className="btn btn-dark creatBtn" onClick={()=>handleAlert()}>Create</button>

        } */}
            <section className="homeMain">
                <section className='mintSec'>
                    <div>
                        <h3 className='text-center heading'>Cross Chain Bridge</h3>
                    </div>
                    <div className="claimBtnDiv">
                        <button className="btn createBtn btn-block py-2" name="create" onClick={(e) => createFormShow(e)} id="create">Create</button>
                        <button className="btn claimBtn btn-block m-0 py-2" name="claim" onClick={(e) => claimFormShow(e)} id="claim">Claim</button>
                    </div>
                    {showClaim ?
                        <>
                            <div className="claimCardMain">

                                {claimData.length > 0
                                    ?
                                    claimData.map((item, key) => (

                                        <div className="claimMain" key={key}>
                                            <div className="date">Time:  {moment(item.date).fromNow()}</div>
                                            {item.chainId == bscId ?
                                                <p className="currency">
                                                    <span><img src={baseIco} alt="" /></span>  BASE  to
                                                    <span className="pl-1"><img src={erc20} alt="" /></span> ETHEREUM
                                                </p>
                                                :
                                                <p className="currency">
                                                    <span ><img src={erc20} alt="" /></span> ETHEREUM to
                                                    <span className="pl-1"><img src={baseIco} alt="" /></span>  BASE
                                                </p>

                                            }
                                            <div className="contentMain">
                                                <div className="col1"><img src={TenupIcon} alt="" /> Tenup </div>
                                                <div className="col2">
                                                    <p>Amount</p>
                                                    <h5>{item.amount}</h5>
                                                </div>
                                            </div>
                                            {item.chainId == bscId && (item.status == "0" && item.approval == "pending") && <button className="btn btn-block claimtokenbtn disabled">Pending For Admin Approval</button>}
                                            {item.chainId == ethId && (item.status == "0" && item.approval == "pending") && <button className="btn btn-block claimtokenbtn disabled">Pending For Admin Approval</button>}
                                            {item.chainId == bscId && (item.status == "0" && item.approval == "approve") && <button className="btn btn-block claimtokenbtn" onClick={() => claimToEth(item)}>Claim to ETH</button>}
                                            {item.chainId == ethId && (item.status == "0" && item.approval == "approve") && <button className="btn btn-block claimtokenbtn" onClick={() => claimToBSC(item)} >Claim to BASE</button>}
                                            {item.status != "0" && <button className="btn btn-block claimtokenbtn disabled">Claimed</button>}
                                            {(item.status == "0" && item.status == "unapprove") && <button className="btn btn-block claimtokenbtn disabled">Request un-approve by admin</button>}

                                        </div>
                                    ))
                                    :
                                    <div className="claimMain mt-0">
                                        <h4 className="text-center ">Deposit token first to execute claim</h4>
                                    </div>
                                }
                                
                                {/* <div className="claimMain" >
                                    <p className="currency">
                                        <span><img src={bep20} alt="" /></span>  BEP20  to
                                        <span className="pl-1"><img src={erc20} alt="" /></span> ERC20
                                    </p>
                                    <div className="contentMain">
                                        <div className="col1"><img src={TenupIcon} alt="" /> Tenup </div>
                                        <div className="col2">
                                            <p>Amount</p>
                                            <h5>0</h5>
                                        </div>
                                    </div>
                                    <button className="btn btn-block claimtokenbtn disabled">Pending For Admin Approval</button>
                                </div> */}
                            </div>

                        </>

                        :
                        <>
                            <div className="form-group amountDivMain">
                                <div className="amountDiv">
                                    <p className="ml-3">Enter Amount</p>
                                    <p style={{fontWeight:"bold"}}>Balance: {userBalance.toFixed(2)} TUP </p>
                                </div>
                                <div className="amountInputDiv">
                                    <div className="imgDiv">
                                        <img src={TenupIcon} alt="Tenup" />
                                    </div>
                                    <input type="number" name="amount" max={10000000} value={userData.amount} className="form-control" onChange={(e) => handleChange(e)} placeholder="Enter Amount" required />
                                    <button className="max" onClick={() => handleMax()}>Max</button>
                                </div>
                            </div>

                            <div className="form-group amountDivMain">
                                <div className="amountDiv">
                                    <p className="ml-3">Reciever Address</p>
                                </div>
                                <div className="amountInputDiv">
                                    <input disabled type="text" name="address" value={userData.address} className="form-control" onChange={(e) => handleChange(e)} placeholder="Enter Address" required />
                                </div>
                            </div>
                            <div className="fromToMain">
                                <div className="fromDivMain">
                                    <p className="from ml-3">From</p>
                                    <div className="fromDiv">
                                        {fromToSwitch ?
                                            <>
                                                <img src={erc20} alt="to" />
                                                <p>ETHEREUM</p>
                                            </>
                                            :
                                            <>
                                                <img src={baseIco} alt="from" />
                                                <p>BASE</p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="convertIconDiv" onClick={handleInterchange}>
                                    <img src={convert} alt="convert" id="intImg" />
                                </div>
                                <div className="fromDivMain">
                                    <p className="to ml-3">To</p>
                                    <div className="fromDiv">
                                        {fromToSwitch ?
                                            <>
                                                <img src={baseIco} alt="from" />
                                                <p>BASE</p>
                                            </>
                                            :
                                            <>
                                                <img src={erc20} alt="to" />
                                                <p>ETHEREUM</p>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="feeMain">
                                <div>
                                    {/* <p>Transaction Fee</p> */}
                                    <p>Recieving Amount</p>
                                </div>
                                <div>
                                    {/* <p>3.8884 USD</p> */}
                                    <p>{userData?.amount} Tenup</p>
                                </div>
                            </div>

                            <button type="submit" className="btn CreateCrossBtn btn-block py-2 mt-3 " onClick={() => handleSubmit()} >Create Crosschain Asset</button>

                        </>
                    }
                    <ToastContainer style={{ width: "max-content" }} />


                </section>
            </section>
            {loader &&
                <div className="seekoLoader">
                    <div className="loader">
                        <img src={TenupIcon} alt="loader" />

                        <h2>Transaction in Process</h2>
                        <h4>Migrating your</h4>
                        <p>{userData.amount} <span className="text-bold">TENUP</span> from
                            {chainId == bscId ? <span className="ml-1">BASE to ETHEREUM</span> : <span className="ml-1">ETHEREUM to BASE </span>}
                        </p>
                    </div>
                </div>
            }
            {claimLoader &&
                <div className="seekoLoader">
                    <div className="loader">
                        <img src={TenupIcon} alt="loader" />

                        <h2>Transaction in Process</h2>
                        <h4>Migrating your</h4>
                        <p>{userData.amount} <span className="text-bold">TENUP</span> from
                            {chainId == bscId ? <span className="ml-1">ETHEREUM to BASE </span> : <span className="ml-1">BASE to ETHEREUM</span>}
                        </p>
                    </div>
                </div>
            }
            <div className="txModal">
                <Modal show={showSwitch} onHide={handleClose}>

                    <Modal.Body>
                        {chainId == bscId ?
                            <h2 className="mb-0">Please switch your network to ethereum</h2>
                            :
                            <h2 className="mb-0">Please switch your network to BASE</h2>
                        }
                        {/* <p>Your wallet will ask permission to switch network. <br /> Please grant it to proceed</p>

                        <div className="btnDiv ">
                            <button className="btn cancel">Cancel</button>
                            {chainId == bscId?
                            <button className="btn confirm" onClick={()=>switchToEth()}>Confirm</button>
                            :
                            <button className="btn confirm" onClick={()=>switchToBsc()} >Confirm</button>
                            }

                        </div> */}
                    </Modal.Body>

                </Modal>

                <Modal show={showTxFail} onHide={handleCloseTxFail}>

                    <Modal.Body>
                        <div className="modalIco mb-4">
                            <img src={failicon} alt="" />
                        </div>
                        <h2>Transaction Failed</h2>
                        <p>The transaction cannot succeed due to error. This is probably an issue with one of the token you are swapping</p>

                    </Modal.Body>

                </Modal>

                <Modal show={showTxComplete} onHide={handleCloseTxComplete}>

                    <Modal.Body>
                        <div className="modalIco mb-4">
                            <img src={complete} alt="" />
                        </div>
                        <h2>Transaction Completed</h2>

                        <p>You've successfully migrated your {userData?.amount} TENUP from {chainId == bscId ? <span>BASE to ETHEREUM</span> : <span>ETHEREUM to BASE </span>}</p>
                        {chainId == bscId ?
                            <a className="hashLink" href={`${process.env.REACT_APP_baseExplorer}/tx/${currentTxHash}`} target="_blank" ><img src={completeIco} alt="complete" /> View on Exporer</a>
                            :
                            <a className="hashLink" href={`${process.env.REACT_APP_ethExplorer}/tx/${currentTxHash}`} target="_blank" ><img src={completeIco} alt="complete" /> View on Exporer</a>
                        }
                        

                    </Modal.Body>
                </Modal>

                <Modal show={showClaimComplete} onHide={handleCloseClaimComplete}>

                    <Modal.Body>
                        <div className="modalIco mb-4">
                            <img src={complete} alt="" />
                        </div>
                        <h2>Transaction Completed</h2>

                        <p>You have successfully claimed your TENUP from {chainId == bscId ? <span>ETHEREUM to BASE </span> : <span>BASE to ETHEREUM</span>}</p>
                        {chainId == bscId ?
                            <a className="hashLink" href={`${process.env.REACT_APP_baseExplorer}/tx/${currentTxHash}`} target="_blank" ><img src={completeIco} alt="complete" /> View on Exporer</a>
                            :
                            <a className="hashLink" href={`${process.env.REACT_APP_ethExplorer}/tx/${currentTxHash}`} target="_blank" ><img src={completeIco} alt="complete" /> View on Exporer</a>
                        }

                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
